
.form-checkbox-list{
  label{
    float:left;
  }
  ul{
    list-style: none;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  li{
    color: #AAAAAA;
    display: block;
    position: relative;
    float: left;
    width: 100%;
    &:hover label{
    	color: #FFFFFF;
    }
    &:hover .check {
      border: 5px solid #FFFFFF;
    }
    input[type=radio]{
      position: absolute;
      visibility: hidden;
    }
    label{
      display: block;
      position: relative;
      font-weight: 300;
      font-size: 1.35em;
      text-align: left;
      text-transform: inherit;
      padding: 10px 25px 25px 45px;
      margin: 7px auto;
      height: 30px;
      z-index: 9;
      cursor: pointer;
      transition: all 0.25s linear;
    }
    .check{
      display: block;
      position: absolute;
      border: 5px solid rgba(255, 255, 255, 0.6);
      border-radius: 100%;
      height: 32px;
      width: 32px;
      top: 12px;
      left: 0;
    	z-index: 5;
    	transition: border .25s linear;
    }
    .check::before {
      display: block;
      position: absolute;
    	content: '';
      border-radius: 100%;
      height: 16px;
      width: 16px;
      top: 3px;
    	left: 3px;
      margin: auto;
    	transition: background 0.25s linear;
    }
  }
  input[type=radio]:checked ~ .check {
    border: 5px solid white;
  }

  input[type=radio]:checked ~ .check::before{
    background: white;
  }

  input[type=radio]:checked ~ label{
    color: white;
  }
}
