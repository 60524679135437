.list-unstyled {
  padding: 0;
  margin: 0;
  list-style: none;
}
.list-inline{
  @extend .list-unstyled;
  > li {
    display: inline-block;
  }
}
