
.short-animate {
  transition: .5s ease-in-out;
}

.long-animate {
  transition: .5s .5s ease-in-out;
}

.lightbox-wrapper, .lightbox-wrapper_pf {
  color: white;
  position: fixed;
  top: -100vh;
  bottom: 100vh;
  left: 0;
  right: 0;
  z-index: 889 !important;
  opacity: 0;

  &:target {
    top: 0%;
    bottom: 0%;
    opacity: 1;
    ~ #lightbox-controls {
      top: 0px;
    }
    ~ #lightbox-controls #close-lightbox:after {
      width: 50px;
    }
    ~ #lightbox-controls #close-lightbox:before {
      height: 50px;
    }
  }

  .lightbox-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &.lightbox-prev-btn {
      left: 25px;
    }

    &.lightbox-next-btn {
      right: 25px;
    }

    i.fa {
      color: #969696;
    }
  }
}

.lightbox-wrapper {
  background: $lightboxCTAbackground;

  .table-wrapper {
    display: table;
    width: 100%;
    height: 100%;

    .table-cell {
      display: table-cell;
      width: 100%;
      height: 100%;
      vertical-align: middle;
      text-align: center;

      .lightbox-headings {
        h1 {
          margin-top: 0;

          br {
            display: none;

            @media only screen and (min-width: $mobileBreakpoint) {
              display: inline-block;
            }
          }
        }

        h2 {
          font-size: 2rem;
          color: $darkColorBackground;
          font-weight: 400;
          margin-bottom: 5px;
        }
      }

      .lightbox-btn-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media only screen and (min-width: $mobileBreakpoint) {
          flex-direction: row;
        }

        a {
          &:first-child {
            margin-right: 0;
            margin-bottom: 8px;

            @media only screen and (min-width: $mobileBreakpoint) {
              margin-right: 8px;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

#lightbox-controls {
  position: fixed;
  height: 70px;
  width: 70px;
  top: -70px;
  right: 0;
  z-index: 890 !important;
  background: rgba(0, 0, 0, .1);
}

#close-lightbox {
  display: block;
  position: absolute;
  overflow: hidden;
  height: 50px;
  width: 50px;
  text-indent: -5000px;
  right: 10px;
  top: 10px;
  transform: rotate(45deg);

  &:before, &:after {
    content: '';
    display: block;
    position: absolute;
    background: white;
    border-radius: 2px;
  }

  &:before {
    height: 0px;
    width: 3px;
    left: 24px;
    top: 0;
    transition: .5s .5s ease-in-out;
  }

  &:after {
    width: 0px;
    height: 3px;
    top: 24px;
    left: 0;
    transition: .5s 1s ease-in-out;
  }
}
.flexAlign {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;

  @media only screen and (min-width: $mobileBreakpoint) {
    flex-direction: row;
  }
}

.lightboxedMenu {
  width: 90% !important;
  max-width: 500px !important;

  &.services {
    max-width: 1000px !important;
  }

  @media only screen and (min-width: $mobileBreakpoint) {
    width: 50% !important;

    &.services {
      width: 80% !important;
    }
  }

  hr {
    margin-top: 50px;
  }

  h4.title {
    margin: 8px 0 12px 0;
    color: $generalFontColor;
    font-size: 1.3em;
    font-weight: 600;
    text-transform: uppercase;
    background-color: white;
    padding: 10px 16px;
    z-index: 1;
    box-shadow: 7px 7px 14px rgba(0,0,0,0.4);
    display: inline-block;
    left: -8px;
    position: relative;
    border-radius: 9px 3px 9px 3px;
    line-height: 1.3em;

    @media only screen and (min-width: $mobileBreakpoint) {
      font-size: 1.65em;
    }
  }

  h3.subtitle {
    margin: 8px 0 12px 0;
    transform: skewY(-4deg);
    line-height: 1.3em;
  }

  .top-title {
    position: relative;
    color: $primaryColor;
    font-size: 1.5em;
    font-weight: 600;
    width: 100%;
    padding: 18px 0 0 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 1.3em;
    margin-top: 25px;

    span {
      &.subtitle {
        color: #000;
        font-size: .75em;
        font-weight: 400;
        text-align: center;
      }
    }

    &:after {
      content: "";
      width: 100%;
      height: 50px;
      background-image: url("/assets/img/title_header_light.svg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: auto 50%;
    }

    @media only screen and (min-width: $mobileBreakpoint) {
      font-size: 2em;
    }
  }

  .mid-title {
    position: relative;
    color: #000;
    z-index: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 25px;
    text-align: justify;

    p {
      line-height: 1.4em;
      font-weight: 500;
    }

    span {
      display: block;
    }

    @media only screen and (min-width: $mobileBreakpoint) {
    }
  }

  ul {
    list-style: none;
    padding-left: 0;
    margin-top: 0;

    li {
      padding: 7px 12px;
      background-color: rgba(255,255,255,0.15);
      left: 0;
      margin-bottom: 3px;
      position: relative;
      transform: skewY(-4deg);
      box-shadow: 2px 2px 7px rgba(0,0,0,0.2);
      border-radius: 9px 3px 9px 3px;

      strong {
        text-transform: uppercase;
        font-size: 1.1em;
      }

      &:nth-child(2n+1) {
        background-color: rgba(255,255,255,0.25);
        left: 0;
      }
    }
  }
}
