section.section-services {
  padding: 75px 0;

  .section-content-wrapper {
    .text-content-wrapper {
      h1, p {
        @include brooklynLight;
      }
    }

    .service-list-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      @media screen and (min-width: 768px) {
        flex-direction: row;
        padding: 0;
        margin: 30px 0;
        flex-flow: row wrap;
      }

      .service-item {
        position: relative;
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: left top;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-grow: 1;
        flex-basis: 25%;
        margin: 25px 25px 25px 30px;
        padding: 0;
        min-height: 200px;
        background-color: #bedae3;
        width: 85%;

        &.one, &.two, &.three {
          background-size: auto 100%;

          &:after {
            content: "";
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            width: 70px;
            height: 70px;
            position: absolute;
            top: -25px;
            right: -25px;
            z-index: 0;
          }

          @media screen and (min-width: 768px) {
            background-size: cover;
          }
        }

        &.one {
          background-image: url("/assets/img/step1.svg");

          &:after {
            background-image: url("/assets/img/icn_calendar.svg");
          }
        }

        &.two {
          background-image: url("/assets/img/step2.svg");

          &:after {
            background-image: url("/assets/img/icn_pictures.svg");
          }
        }

        &.three {
          background-image: url("/assets/img/step3.svg");

          &:after {
            background-image: url("/assets/img/icn_screen.svg");
          }
        }

        .text-content {
          width: 56%;
          padding: 9px;
          font-weight: 600;
          font-size: inherit;

          span {
            @include brooklynMedium;
            max-width: 100%;
          }
        }

        @media screen and (min-width: 515px) {
          width: 78%;
        }
        @media screen and (min-width: 768px) {
          padding: 65px 0;
          min-height: inherit;

          .text-content {
            width: 58%;
            font-size: 1.3vw;
          }
        }

        @media screen and (min-width: 1140px) {
          margin: 25px;
          .text-content {
            font-size: 1.2vw;
          }
        }
      }

      @media screen and (min-width: 768px) {
        align-items: stretch;
      }
    }
  }
}