a.button, button.button {
  @include brooklynMedium;

  color: $generalFontColor;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1.16em;
  font-weight: 600;
  text-align: center;
  padding: 1.1rem 3rem;
  transform: scale(1,1);
  transition: all 0.5s ease-in-out;
  transition-timing-function: cubic-bezier(3, 1, 2, 7);
  box-shadow: 0 0 4px -12px rgba(0,0,0,0.9);
  display: inline-block;

  &.button-primary {
    color: $primaryColorReverse;
    background-color: $buttonPrimaryBackground;
    text-decoration: none;
    letter-spacing: 0.1em;
    line-height: 1.25em;

    &:hover {
      background-color: darken($buttonPrimaryBackground, 10%);
    }
  }

  &.button-secondary {
    color: #FFF !important;
    background-color: $buttonSecondaryBackground;
    line-height: 1.25em;
    display: inline-block;

    &:hover {
      background-color: lighten($buttonSecondaryBackground, 10%);
    }
  }

  &.button-dark {
    color: white !important;
    background-color: $darkColorBackground;
    //padding: 1.1em 2.3em;
    border-radius: 128px;
    line-height: 1.25em;
    &:hover {
      background-color: lighten($darkColorBackground, 5%);
    }
  }

  &.button-mobile {
    color: $WhiteBackground !important;
    border-radius: 0;
    font-size: 1.6rem;
    padding: 20px;
    width: 100%;
    height: 100%;
    line-height: 1em;
    position: relative;

    &:hover {
      background-color: inherit;
      color: black !important;
    }
  }

  &:hover {
    transform: scale(1.05,1.05);
    transition: all 0.5s ease-in-out;
    transition-timing-function: cubic-bezier(3, 1, 2, 7);
    box-shadow: 0 17px 10px -13px rgba(0,0,0,.5);
  }

  &.button-block {
    display: block;
  }
}
