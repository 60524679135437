section.section-advantages {
  padding: 75px 0;

  .section-content-wrapper {
    .text-content-wrapper {
      h1, p {
        @include brooklynLight;
      }
    }

    .advantages-list-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &.spaces {
        margin: 30px 0;
      }

      @media screen and (min-width: 767px) {
        flex-direction: row;
        align-items: flex-start;
      }

      .advantage-item {
        width: 100%;
        margin: 0 15px;
        text-align: center;
        margin-bottom: 50px;

        &:last-child {
          margin-bottom: 15px;
        }

        @media screen and (min-width: 767px) {
          width: 29.33%;
        }

        span {
          @include brooklynMedium;

          &.copy-size {
            font-size: 1.6rem;
            line-height: 1.25em;
          }
          &.label {
            display: block;
            margin-top: 15px;
          }
        }
      }
    }
  }
}