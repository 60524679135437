section.section-video {
  background-image: url("/assets/img/bkg-accueil.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;

  @media screen and (min-width: 1100px) {
    background-position: center;
  }

  .section-content-wrapper {
    padding: 100px 0;

    @media screen and (min-width: 767px){
      padding: 200px 0;
    }

    .text-content-wrapper {
      h1, p {
        @include brooklynLight;
      }

      .video-wrapper {
        margin-top: 50px;

        video {
          max-width: 100%;
          outline: 0;
          box-shadow: 0 3px 4px 1px #252424;
        }
      }
    }
  }
}