* {
  box-sizing: border-box;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
html,
body {
	min-height: 100vh;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
}
html {
  font-size: 62.5%;
}
body {
  font: 13px/1.5 $generalFontFamily;
  color: $generalFontColor;
  transition: all .4s .3s ease-in;
  margin-top: calc(6rem + 50px);
}
