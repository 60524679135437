.footer {
  background: $footerBackground;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 100px 30px;
  margin-bottom: 0;

  .footer-logo-wrapper {
    img {
      width: 200px;
    }
  }

  .contacts {
    .address-wrapper {
      margin-top: 25px;

      p {
        color: $primaryColor;
      }
    }

    .phones-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media only screen and (min-width: $mobileBreakpoint) {
        flex-direction: row;
      }

      .phone-item {
        font-size: 2rem;
        color: $primaryColor;
        margin-bottom: 8px;

        @media only screen and (min-width: $mobileBreakpoint) {
          margin-bottom: 0;
        }

        a {
          color: $primaryColor;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
            color: #3a7be2;
          }
        }

        &:first-child {
          margin-right: 0;

          @media only screen and (min-width: $mobileBreakpoint) {
            margin-right: 15px;
          }
        }
      }
    }

    .contact-email-wrapper {
      a {
        @include brooklynMedium;
        font-size: 1.5rem;
        color: $primaryColor;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
          color: #3a7be2;
        }
      }
    }
  }

  .social-media-link {
    margin-top: 25px;

    a {
      text-decoration: none;

      &:hover {
        opacity: .5;
      }
    }
  }

  .footer-links-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 25px;

    .footer-link-item, .separator {
      &:not(:last-child) {
        margin-right: 8px;
      }

      a {
        color: $primaryColor;
        font-weight: 600;
        text-decoration: none;

        &:hover {
          color: #3a7be2;
        }
      }
    }

    .footer-link-item {
      margin-bottom: 8px;

      @media only screen and (min-width: $mobileBreakpoint) {
        margin-bottom: 0;
      }
    }

    .separator {
      display: none;

      @media only screen and (min-width: $mobileBreakpoint) {
        display: inline-block;
      }
    }

    @media only screen and (min-width: $mobileBreakpoint) {
      flex-direction: row;
    }
  }

  .created-by-wrapper {
    margin-top: 25px;

    p.title {
      @include brooklynLight;
      font-size: 1.25rem;
      font-weight: 400;
      margin-bottom: 8px;
    }

    .flex-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media only screen and (min-width: $mobileBreakpoint) {
        flex-direction: row;
        align-items: flex-end;
      }

      .created-item {
        margin-bottom: 15px;
        transition: .2s ease;

        &:not(:last-child) {
          margin-right: 0;

          @media only screen and (min-width: $mobileBreakpoint) {
            margin-right: 25px;
          }
        }

        @media only screen and (min-width: $mobileBreakpoint) {
          margin-bottom: 0;
        }
      }
    }
  }
}

.cta-footer {
  @include brooklynMedium;
  position: fixed;
  display: flex;
  justify-content: space-between;
  bottom: 0;
  z-index: 500;
  width: 100%;
  background-color: $ctaFooterBackground;
  text-align: center;
  box-shadow: 0 0px 12px rgba(0, 0, 0, 0.7);

  @media only screen and (min-width: $mobileBreakpoint) {
    display: none;
  }
}

.scroll-top {
  width: 75px;
  height: 75px;
  position: fixed;
  bottom: 35px;
  right: -20px;
  opacity: 0;
  transition: all 0.8s;
  transition-timing-function: cubic-bezier(3, 1, 2, 7);

  @media only screen and (min-width: $mobileBreakpoint) {
    bottom: 25px;
    right: 20px;
  }
}
.scroll-top i {
  display: inline-block;
  color: $footerBlue;
}
