#ac-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .4);
  z-index: 1001;

  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
#popup {
  width: 95%;
  height: auto;
  background: #fff;
  border-radius: 0;
  box-shadow: 0px 25px 12px -20px rgba(0,0,0,0.5);
  position: relative;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;

  p {
    font-size: 0.9em;
    font-weight: 500;

    strong {
      font-weight: 800;
    }
  }

  .button, button.button{
    color: $generalFontColor;
    font-family: $titleFontFamily;
    font-weight: 600;
    letter-spacing: 0.3px;
    text-decoration: none;
    transition: background-color 0.3s;
    text-transform: uppercase;
    cursor: pointer;
    &.button-primary{
      color: #fff;
      background-color: $buttonPrimaryBackground;
      padding: 1rem 3rem;
      text-decoration: none;
      border-radius: 5px;
      &:hover{
        background-color: $buttonPrimaryBackgroundHover;
      }
    }
    &.button-secondary{
      background-color: $buttonSecondaryBackground;
      font-size: 1.6rem;
      padding:0.8em 2em;
      &:hover{
        background-color: lighten($buttonSecondaryBackground, 5%);
      }
    }
    &.button-folder{
      color: $colorBackground;
      background-color: white;
      border: solid 3px white;
      padding: 1rem 3rem;
      text-decoration: none;
      &:hover{
        background-color: $buttonPrimaryBackgroundHover;
      }
    }
    &.button-concours{
      color: $buttonTextColor;
      background-color: transparent;
      border: solid 2px white;
      padding: 0.5rem 1.8rem;
      text-decoration: none;
      margin-top: 8px;
      z-index: 1;
      font-weight: 700;
      text-shadow: none;

      &:hover{
        color: rgba(195, 61, 67, 0.97);
        background-color: $buttonTextColor;
      }
    }
  }

  [type=reset], [type=submit], button, html [type=button] {
    -webkit-appearance: none;
  }

  .priceTag {

    h1 {
      font-size: 3em;
      font-weight: 800;
      margin: 12px 0;
    }
    sup {
      top: -15px;
      font-size: 0.35em;
      font-weight: 800;
    }
    sub {
      bottom: 2px;
      font-size: 0.15em;
      font-weight: 400;
      left: 0;
    }
  }

  @media only screen and (min-width:$mobileBreakpoint) {
    width: 50%;

    p {
      font-size: 1.15em;
    }

    .priceTag {

      h1 {
        font-size: 6em;
      }
      sup {
        top: -30px;
      }
      sub {
        left: -55px;
      }
    }
  }
}
