@mixin brooklynBold {
  font-family: $samuelBrooklynBold, 'Montserrat', Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 200;
}

@mixin brooklynLight {
  font-family: $samuelBrooklynLight, 'Montserrat', Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 200;
}

@mixin brooklynMedium {
  font-family: $samuelBrooklynMedium, 'Montserrat', Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 200;
}

