section.section-consultation {
  position: relative;
  background-image: url("/assets/img/bkg_consultation.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;

  @media screen and (min-width: 1100px) {
    background-position: center;
  }

  .white-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, .6);
    display: block;

    @media screen and (min-width: 515px){
      display: none;
    }
  }

  .section-content-wrapper {
    padding: 100px 0;

    @media screen and (min-width: 1100px) {
      padding: 300px 0;
    }

    .text-content-wrapper {
      text-align: center;

      @media screen and (min-width: 515px){
        text-align: left;
      }

      h1 {
        @include brooklynLight;
      }

      p {
        @include brooklynMedium;
        margin-bottom: 50px;
      }
    }
  }
}