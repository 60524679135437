.header {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top:0;
  z-index: 11;
  width:100%;
  padding: 20px;
  transition: background-color .25s ease-in-out;

  .logo {
    text-align: center;

    img{
      &.logo-uman {
        width: 190px;

        @media only screen and (min-width:515px) {
          width: inherit;
        }
      }
    }
  }

  @media only screen and (min-width:767px) {
    padding: 20px 30px;
    justify-content: space-between;
  }

  img.logomobile {
    margin: 8px 0 25px 0;
  }

  &.fixed {
    background-color: rgba(0,0,0,.4);
  }
}
