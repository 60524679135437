section.section-testimonies {
  //background-image: url("/assets/img/bkg-accueil.jpg");
  background: $primaryColor;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;

  @media screen and (min-width: 1100px) {
    background-position: center;
  }

  .section-content-wrapper {
    padding: 100px 0;

    &.padding-bottom-0 {
      padding-bottom: 0;
    }

    &.padding-top-0 {
      padding-top: 0;
    }

    .slider-container {
      .slide {
        outline: 0px;

        .testimony-wrapper {
          position: relative;

          .testimony-item {
            @include brooklynLight;
            display: inline-block;
            padding: 15px 20px;
            margin: 50px 0;
            position: relative;
            border: 2px solid #FFF;
            border-radius: 10px;
            width: 100%;
            color: #FFF;
            text-align: left;
            z-index: 0;
            font-size: 1.7rem;

            @media screen and (min-width: 515px){
              width: 50%;
            }

            .caption-wrapper {
              display: block;
              align-items: center;
              position: absolute;
              z-index: 0;
              bottom: -38px;
              right: 30px;
              width: 100%;
              text-align: right;

              span {
                &.label {
                  @include brooklynMedium;
                  margin-right: 45px;
                  font-size: 9px;

                  @media screen and (min-width: 515px) {
                    font-size: 12px;
                  }
                }
              }

              img {
                &.triangle {
                  width: 40px;
                }
              }

              &:after {
                content: "";
                position: absolute;
                background-color: $primaryColor;
                width: 30px;
                height: 35px;
                border: 2px solid white;
                border-top: 0 solid transparent;
                top: -13.2px;
                right: 2px;
                z-index: 1;
                clip-path: polygon(0 100%,100% 0, 100% 0, 25% 0,0 0);
              }

              &:before {
                content: "";
                border-right: 2px solid white;
                transform: rotate(44deg);
                position: absolute;
                right: 18px;
                top: -19px;
                height: 47px;
                display: block;
                z-index: 3;
              }
            }
          }
        }
      }

      .slick-prev, .slick-next {
        display: none !important;

        @media screen and (min-width: 515px) {
          display: block !important;
        }
      }

      .slick-prev:before {
        content: "\f053";
        font: normal normal normal 14px/1 FontAwesome;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        color: rgba(255, 255, 255, .5);
        font-size: 30px;
      }

      .slick-next:before {
        content: "\f054";
        font: normal normal normal 14px/1 FontAwesome;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        color: rgba(255, 255, 255, .5);
        font-size: 30px;
      }
    }

    .text-content-wrapper {
      h1, p {
        @include brooklynLight;
      }

      p {
        margin-bottom: 0;
      }
    }
  }
}