section.section-about {
  position: relative;

  .section-content-wrapper {
    .white-bg, .black-bg {
      position: relative;
      padding: 75px 0;
    }

    .black-bg {
      background-color: $darkColorBackground;

      h2, h3 {
        text-transform: uppercase;
      }

      h2 {
        font-size: 3rem;
      }

      h3 {
        @include brooklynLight;
        margin-bottom: 0;
        margin-top: 25px;
        font-size: 1.7rem;
      }

      h2 {
        @include brooklynMedium;
        margin: 8px 0 0 0;
      }
    }

    .text-content-wrapper {
      h1, p, a {
        @include brooklynLight;
      }

      h1 {
        margin: 0;
        padding: 0;
      }
    }

    .icn-uman-wrapper {
      position: absolute;
      width: 100%;
      bottom: -72px;
      left: 0;
      text-align: center;
      z-index: 5;
    }
  }
}