section {
  h1, h2, h3, h4, h5 {
    margin: 0;
  }

  h1 {
    br {
      display: none;

      @media screen and (min-width: 900px) {
        display: inline-block;
      }
    }
  }

  .section-content-wrapper {
    .container {
      &.w100 {
        width: 100%;
        max-width: 1200px;
      }
    }
  }
}

.action-wrapper {
  display: inline-flex;
  margin-top: 0;

  @media screen and (min-width: 1140px) {
    margin-top: 25px;
  }
}

.text-content-wrapper {
  width: 100%;
  margin: 0 auto;
  text-align: center;

  @media screen and (min-width: 770px) {
    width: 75%;
  }

  &.w100 {
    width: 100%;
  }
}

.primary-color {
  color: $primaryColor;
}

.white-color {
  color: #FFF;
}

.copy-primary {
  color: $copy-primary;
}

.footer-copy {
  color: $footerBlue;
}

.h1-heading-size {
  font-size: 3.5rem;
}

.copy-size {
  font-size: 2rem;
}

.resize-mobile {
  font-size: 1.7rem;

  @media screen and (min-width: 515px) {
    font-size: 1.9rem;
  }
  @media screen and (min-width: 1024px) {
    font-size: 2rem;
  }
}

.fw500 {
  font-weight: 500;
}

.fw600 {
  font-weight: 600;
}

.bottom-shadow {
  position: relative;
  box-shadow: 0 5px 13px 0 rgba(0,0,0,0.45);
}

.top-shadow {
  position: relative;
  box-shadow: 0 -5px 13px 0 rgba(0,0,0,0.4);
}

.margin-top-25 {
  margin-top: 25px;
}