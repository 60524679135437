section.section-simulation {
  background-image: url("/assets/img/bkg_3d_image.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;

  @media screen and (min-width: 1100px) {
    background-position: center;
  }

  .section-content-wrapper {
    padding: 100px 0;

    .text-content-wrapper {
      .icon {
        margin-bottom: 15px;
      }

      h1, p {
        @include brooklynMedium;
      }
    }

    @media screen and (min-width: 768px) {
      padding: 200px 0;
    }
  }
}