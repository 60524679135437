section.hero-banner {
  width: 100%;
  height: 92vh;
  background-image: url("/assets/img/bkg_head_image.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 60%;

  @media screen and (max-height: 640px) and (min-width: 515px) {
    height: 700px;
  }

  @media screen and (min-width: 515px) {
    height: 73vh;
  }
  @media screen and (min-width: 770px) {
    background-position: left;
  }

  .hero-wrapper {
    display: table;
    width: 100%;
    height: 100%;

    .inner-wrapper {
      display: table-cell;
      width: 100%;
      height: 100%;
      vertical-align: middle;

      .text-content-wrapper {
        h1 {
          @include brooklynMedium;
          margin-bottom: 25px;
          font-size: 3rem;

          @media screen and (min-width: 515px) {
            font-size: 4rem;
          }

          @media screen and (min-width: 770px) {
            font-size: 4.5rem;
          }
        }

        h2 {
          @include brooklynMedium;
          font-size: 2rem;

          @media screen and (min-width: 515px) {
            font-size: 2.5rem;
          }
        }
      }
    }
  }
}