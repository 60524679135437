@font-face {
  font-family: 'brooklyn_samuelsno5_bold';
  src: url('/assets/fonts/samuelstype_-_brooklynsamuelsfive-bold-webfont.woff2') format('woff2'),
  url('/assets/fonts/samuelstype_-_brooklynsamuelsfive-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'brooklyn_samuelsno5_light';
  src: url('/assets/fonts/samuelstype_-_brooklynsamuelsfive-light-webfont.woff2') format('woff2'),
  url('/assets/fonts/samuelstype_-_brooklynsamuelsfive-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'brooklyn_samuelsno5_medium';
  src: url('/assets/fonts/samuelstype_-_brooklynsamuelsfive-medium-webfont.woff2') format('woff2'),
  url('/assets/fonts/samuelstype_-_brooklynsamuelsfive-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}