
// Couleurs
$primaryColor: #ff5958;
$primaryColorReverse: #F2F2F2;
$darkColorBackground: #4b4f57;
$colorBackground: #8e2426;
$LightBackground: #f8f8f8;
$WhiteBackground: #ffffff; // blanc
$copy-primary: #4b4f57;
$footerBlue: #a3c7d2;

// Fontes
$samuelBrooklynBold: 'brooklyn_samuelsno5_bold';
$samuelBrooklynLight: 'brooklyn_samuelsno5_light';
$samuelBrooklynMedium: 'brooklyn_samuelsno5_medium';
$titleFontFamily: 'Montserrat', Helvetica, sans-serif;
$generalFontFamily: $titleFontFamily;
$generalFontColor: black;
$textShadow: 0 0 7px rgba(12, 12, 12, 0.58);

// $Header
$headerBackground: white;
$headerHeight: 70px;
$headerHeightMobile: 0;

// button
$buttonTextColor: black;
$buttonPrimaryBackground: $primaryColor;
$buttonPrimaryBackgroundHover: rgb(149, 39, 37);
$buttonSecondaryBackground: $copy-primary;

// form
$inputPlaceholder: #3b3c3c;

// $Footer
$footerBackground: $WhiteBackground;
$footerFontColor: $WhiteBackground;
$ctaFooterBackground: $primaryColor;
$ctaFooterHeight: 60px;

// #LightboxCTA
$lightboxCTAbackground: rgba(131, 158, 167, 0.97);

// Sections
// - Secion punch
$sectionPunchOverlayBackground: rgba(0, 0, 0, 0.29);
// - Section About
$sectionAboutBgColor: rgb(145, 228, 244);
$sectionAboutCopyColor: #4e4e4e;
// - Section Problems
$sectionProblemsHeadingColor: $primaryColor;
$sectionProblemsCopyColor: #4e4e4e;
// - Section Important
$sectionImportantBgColor: #035098;
// - Section Covid
$sectionCovidBgColor: #182050;
$sectionCovidHeadingColor: #c52323;

// Mobile breakooint
$mobileBreakpoint: 700px;
