.header {
  .menu {
    display: none;

    .cta-flex-wrapper {
      display: flex;
      align-items: center;
    }

    @media screen and (min-width: $mobileBreakpoint) {
      display: block;
    }
  }
}
